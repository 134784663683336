<template>
  <MainLayout>
    <form action="" @submit.prevent="submit">
      <div class="intro-y mt-5 mr-4">
        <div>
          <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
          <div v-if="alertFailed">
            <AlertFailed
              v-for="(item, index) in messageAlert"
              :key="index"
              :message="item"
              :dismissable="true"
            />
          </div>
        </div>
        <div
          v-if="isLoading"
          class="absolute bg-white w-full h-full z-100 flex justify-center items-center z-50"
        >
          <div class="opacity-100">
            <LoadingSpinner :is-loading="isLoading" :use-state="useState" />
          </div>
        </div>
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 2xl:col-span-12 -mb-10 pb-10">
            <div class="intro-y box pb-6">
              <div
                class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">
                  Form Tambah Inpassing
                </h2>
              </div>
              <div class="grid grid-cols-12 gap-x-8 p-10 py-10">
                <div class="col-span-12 mt-4 form-inline">
                  <label class="form-label font-medium w-52"
                    >Golongan/Pangkat <span class="text-red-900">*</span></label
                  >
                  <TailSelect
                    v-model="inpassing['id_pangkat_gol']"
                    :options="{
                      search: false,
                      classNames: 'form-control',
                      deselect: false,
                    }"
                  >
                    <option
                      v-for="list in ref_pangkatgol"
                      :key="list.value"
                      :value="list.value"
                      :selected="list.selected"
                      :data-kredit="list.value2"
                    >
                      {{ list.text }}
                    </option>
                  </TailSelect>
                </div>
                <div class="col-span-12 mt-4 form-inline">
                  <label class="form-label font-medium w-52"
                    >Nomor SK Inpassing</label
                  >
                  <input
                    v-model="inpassing['sk_pangkat']"
                    class="form-control focus:ring-0"
                  />
                </div>
                <div class="col-span-12 mt-4 form-inline">
                  <label class="form-label font-medium w-52"
                    >Tanggal SK <span class="text-red-900">*</span></label
                  >
                  <input
                    v-model="inpassing['tgl_sk_pangkat']"
                    type="date"
                    class="form-control focus:ring-0 mt-2"
                    autocomplete="off"
                  />
                </div>
                <div class="col-span-12 mt-4 form-inline">
                  <label class="form-label font-medium w-52"
                    >Terhitung Mulai Tanggal
                    <span class="text-red-900">*</span></label
                  >
                  <input
                    v-model="inpassing['tmt_sk_pangkat']"
                    type="date"
                    class="form-control focus:ring-0 mt-2"
                    autocomplete="off"
                  />
                </div>
                <div class="col-span-12 mt-4 form-inline">
                  <label class="form-label font-medium w-52"
                    >Angka Kredit</label
                  >
                  <input
                    v-model="inpassing['angka_kredit']"
                    class="form-control focus:ring-0"
                  />
                </div>
                <div class="col-span-12 mt-4 form-inline">
                  <label class="form-label font-medium w-52"
                    >Masa Kerja (Tahun)</label
                  >
                  <input
                    v-model="inpassing['masa_kerja_gol_thn']"
                    class="form-control focus:ring-0"
                  />
                </div>
                <div class="col-span-12 mt-4 form-inline">
                  <label class="form-label font-medium w-52"
                    >Masa Kerja (Bulan) *</label
                  >
                  <input
                    v-model="inpassing['masa_kerja_gol_bln']"
                    class="form-control focus:ring-0"
                  />
                </div>
              </div>

              <hr />

              <hr />

              <div class="grid grid-cols-12 gap-x-8 p-10 py-10 bg-gray-100">
                <div class="col-span-12 mt-4 form-inline items-start">
                  <label class="form-label font-medium w-52"
                    >Upload Dokumen <span class="text-red-900">*</span>
                    <br />
                    <small class="help">
                      ( Maksimal total ukuran file : 5 MB
                      .xls/.xlsx/.docs/.pdf/.jpg/.bmp/.png )
                    </small>
                  </label>
                  <div class="intro-y form-control border-0 shadow-none pt-0">
                    <div class="mb-6">
                      <button
                        class="btn btn-success w-full md:w-64 mb-2"
                        type="button"
                        @click="addMoreDokumen"
                      >
                        <PlusIcon class="w-4 h-4 mr-2" /> Tambah Dokumen Baru
                      </button>
                      <div class="help text-xs text-yellow-500">
                        Mohon melampirkan file gambar asli yang jelas dan tidak
                        blur
                      </div>
                    </div>
                    <div
                      v-for="(item, index) in dokumen.dataDokumen"
                      :key="index"
                      class="grid grid-cols-12 mb-6 p-5 shadow-lg rounded bg-white"
                    >
                      <div
                        class="col-span-12 2xl:col-span-12 flex items-center py-1 border-b border-gray-200 dark:border-dark-5"
                      >
                        <h2 class="font-medium text-base mr-auto">
                          Dokumen {{ index + 1 }}
                        </h2>
                      </div>
                      <div
                        class="col-span-12 form-inline items-start flex-col xl:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-4 mt-4"
                      >
                        <div class="pb-4">
                          <div class="flex flex-row items-center">
                            <div v-if="!item.nama_file">
                              <div
                                class="btn btn-secondary relative cursor-pointer mr-3"
                              >
                                <LoadingIcon
                                  v-if="onUploadProgress[index]"
                                  icon="oval"
                                  class="w-4 h-4 mr-2"
                                />
                                <UploadIcon
                                  v-else-if="!onUploadProgress[index]"
                                  class="w-4 h-4 mr-2"
                                />
                                Upload File
                                <input
                                  v-bind="item.file"
                                  type="file"
                                  class="inputfile"
                                  @change="onChangeFile($event, index)"
                                />
                              </div>
                              <label
                                class="underline m-0 mr-1 pb-2 text-gray-500"
                                >No file selected</label
                              >
                            </div>
                            <div v-else>
                              <FileIcon class="w-4 h-4 mr-1" />
                              <label class="underline m-0 mr-1">{{
                                item.nama_file
                              }}</label>
                              <div
                                class="text-blue-600 inline-block truncate cursor-pointer"
                                @click="deleteFile(index)"
                              >
                                Batal
                              </div>
                            </div>
                          </div>
                          <div class="form-help">
                            Maksimal total ukuran file : 5 MB,
                            .xls/.xlsx/.docs/.pdf/.jpg/.bmp/.png
                          </div>
                        </div>
                      </div>
                      <div class="col-span-12 2xl:col-span-12 mt-4">
                        <label class="form-label font-medium pb-2"
                          >Nama Dokumen</label
                        >
                        <input
                          v-model="item.nama"
                          type="text"
                          class="form-control"
                          autocomplete="off"
                        />
                      </div>
                      <div class="col-span-12 2xl:col-span-12 mt-4">
                        <label class="form-label font-medium pb-2"
                          >Keterangan</label
                        >
                        <input
                          v-model="item.keterangan"
                          type="text"
                          class="form-control"
                          autocomplete="off"
                        />
                      </div>
                      <div class="col-span-12 2xl:col-span-12 mt-4">
                        <label class="form-label font-medium pb-2"
                          >Jenis Dokumen</label
                        >
                        <TailSelect
                          v-model="item.jenis_dokumen"
                          :options="{
                            search: false,
                            classNames: 'w-full',
                            deselect: false,
                          }"
                        >
                          <option
                            v-for="itemJenisDokumen in listJenisDokumen"
                            :key="itemJenisDokumen.id"
                            :value="itemJenisDokumen.value"
                          >
                            {{ itemJenisDokumen.text }}
                          </option>
                        </TailSelect>
                      </div>
                      <div class="col-span-4 2xl:col-span-12 pt-4 text-right">
                        <button
                          class="btn btn-danger w-full"
                          type="button"
                          @click="remove(index)"
                        >
                          <Trash2Icon class="w-4 h-4 mr-2" /> Hapus
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div
                class="flex justify-end flex-col md:flex-row gap-2 mt-6 mx-6"
              >
                <router-link
                  :to="{ name: 'jabatan-fungsional' }"
                  class="btn py-3 border-slate-300 dark:border-darkmode-400 text-slate-500 w-full md:w-52"
                  >Cancel</router-link
                ><button
                  type="submit"
                  class="btn py-3 btn-primary w-full md:w-52"
                >
                  <SaveIcon class="w-4 h-4 mr-2" /> Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/main/MainLayout";
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertFailed from "@/components/alerts/AlertFailed";
import { ref, sdm } from "@/services/models";
import moment from "moment";
import LoadingSpinner from "@/components/loading-spinner/Main";

export default {
  components: {
    MainLayout,
    AlertSuccess,
    AlertFailed,
    LoadingSpinner,
  },
  data() {
    return {
      alertSuccess: false,
      alertFailed: false,
      messageAlert: [],
      isLoading: false,
      useState: false,
      id: this.$route.params.id,
      inpassing: {
        KODE: this.$store.state.userdata.userData.uid,
        id_sdm: this.$store.state.userdata.userData.idSdm,
        // id_kategori_kegiatan: null,
        id_rwy_inpassing: null,
        id_pangkat_gol: null,
        sk_pangkat: null,
        tmt_sk_pangkat: null,
        angka_kredit: null,
      },
      onUploadProgress: [],
      dokumen: {
        dataDokumen: [],
      },
      ref_pangkatgol: [],
      listJenisDokumen: [
        {
          id: 0,
          value: "",
          text: "Pilih Jenis Dokumen",
          selected: true,
        },
        {
          id: 1,
          value: "3995",
          text: "SK Inpassing",
          selected: true,
        },
        {
          id: 2,
          value: "9999",
          text: "Lainya",
          selected: true,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getPangkatGol();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    async getPangkatGol() {
      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Golongan/Pangkat",
        selected: true,
      };
      this.ref_pangkatgol.push(defaultOpt);
      ref.pangkatgolongan.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.ref_pangkatgol.push({
              id: element.id_pangkat_gol,
              value: element.id_pangkat_gol,
              value2: element.kode_gol,
              text: element.kode_gol + " (" + element.nm_pangkat + ")",
              selected: false,
            });
          });
        }
      });
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    addMoreDokumen() {
      this.dokumen.dataDokumen.push({
        nama_file: null,
        jenis_file: null,
        nama: null,
        jenis_dokumen: null,
        keterangan: null,
        tautan: null,
      });
    },
    remove(index) {
      this.dokumen.dataDokumen.splice(index, 1);
    },
    onChangeFile(event, index) {
      const fd = new FormData();
      fd.append("file", event.target.files[0]);

      this.onUploadProgress[index] = true;

      sdm.InpassingSdm.postCustomPath("kirimFile", fd)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              this.dokumen.dataDokumen[index].nama_file = res.data.fileName;
              this.dokumen.dataDokumen[index].jenis_file = res.data.fileExt;
              // this.alertSuccess = true;
              // this.messageAlert = res.data.message;
            } else {
              // this.alertFailed = true;
              // Object.keys(res.data.message).forEach((key) => {
              //   this.messageAlert.push(res.data.message[key][0]);
              // });
            }
          }
        })
        .finally(() => {
          if (this.onUploadProgress[index] == true)
            return (this.onUploadProgress[index] = false);
        });
    },
    deleteFile(index) {
      return (this.dokumen.dataDokumen[index] = {});
    },
    submit() {
      this.alertSuccess = false;
      this.alertFailed = false;
      this.messageAlert = [];
      this.isLoading = true;

      let formdata = {
        ...this.inpassing,
        ...this.dokumen,
      };

      // console.log(formdata);
      sdm.InpassingSdm.insertData(formdata).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            this.alertSuccess = true;
            this.messageAlert = res.data.message;

            this.$router.push({
              name: "pangkat-inpassing",
              params: {
                messageAlert: this.messageAlert,
                alertSuccess: true,
              },
            });
          } else {
            this.alertFailed = true;
            Object.keys(res.data.message).forEach((key) => {
              this.messageAlert.push(res.data.message[key][0]);
            });
          }
        }

        this.isLoading = false;
        this.scrollToTop();
      });

      // }
    },
  },
};
</script>

<style scoped>
.form-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputfile {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  padding: 10px;
  opacity: 0;
}

.inputfile + .fileinfo {
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputfile:focus + .fileinfo,
.inputfile + .fileinfo:hover,
.inputfile:hover + .fileinfo {
  cursor: pointer;
}

.inputfile + .fileinfo {
  cursor: pointer;
}
</style>
