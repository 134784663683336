const staffMenus = [
  {
    icon: "HomeIcon",
    pageName: "dashboard",
    ability: ["*"],
    title: "Home",
  },
  {
    icon: "usersIcon",
    title: "Profil",
    subMenu: [
      {
        icon: "activityIcon",
        pageName: "datapribadi",
        ability: ["*"],
        title: "Data Pribadi",
      },
      {
        icon: "activityIcon",
        pageName: "pangkat-inpassing",
        ability: ["*"],
        title: "Inpassing",
      },
      {
        icon: "activityIcon",
        pageName: "jabatan-fungsional",
        ability: ["*"],
        title: "Jabatan Fungsional",
      },
    ],
  },
  {
    icon: "briefcaseIcon",
    title: "Diklat",
    subMenu: [
      {
        icon: "activityIcon",
        pageName: "pendidikan-pelatihan",
        ability: ["tendik", "dosen"],
        title: "Pelatihan",
      },
      {
        icon: "activityIcon",
        pageName: "kegiatan-rekognisi",
        ability: ["tendik", "dosen"],
        title: "Kegiatan Rekognisi",
      },
    ],
  },
  {
    icon: "archiveIcon",
    pageName: "download-template-surat",
    ability: ["tendik", "dosen"],
    title: "Template Surat",
  },
  {
    icon: "layersIcon",
    pageName: "user-profile",
    title: "Sister",
    ability: ["tendik", "dosen"],
  },
];

const install = (app) => {
  app.config.globalProperties.$st_menu = staffMenus;
};

export { install as default, staffMenus };
