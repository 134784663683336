import axiosRetry from "axios-retry";
import axios from "axios";
import store from "@/store";
import get from "lodash/get";
// import { isAcademicTeam } from "@/utils/menus";
// import { userdata } from "@/utils/userdata";

const HTTP0 = axios.create({
  baseURL:
    process.env.VUE_APP_API.split("/")[0] +
    "//" +
    process.env.VUE_APP_API.split("/")[2],
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true,
});
const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API || "http://202.45.67.12/api/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true,
});

const REFHTTP = axios.create({
  baseURL: process.env.VUE_APP_REF_API,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// const SIMPONI = axios.create({
//   baseURL: "https://simponi2.mdp.ac.id/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });
//
// const APPS2 = axios.create({
//   baseURL: "https://apps2.mdp.ac.id/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });
//
// const JUPITER = axios.create({
//   baseURL: process.env.VUE_APP_JUPITER || "https://jupiter.mdp.ac.id/pmb/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

HTTP.interceptors.response.use(
  (response) => response,
  async (err) => {
    const status = get(err, "response.status");

    if (status === 419) {
      // Refresh our session token
      await HTTP.get("csrf-token");

      // Return a new request using the original request's configuration
      return HTTP(err.response.config);
    }

    return Promise.reject(err);
  }
);

HTTP.interceptors.request.use((req) => {
  const authorized = store.getters["auth/isAuthenticated"];
  const isexpire = store.getters["auth/isExpire"];
  // const access_token = store.getters["auth/accessToken"];
  const access_token = JSON.parse(localStorage.getItem("_bh"));

  const tokendikti = store.getters["feederpddikti/feedertoken"];

  const tokensister = store.getters["sisterpddikti/sistertoken"];

  // const institusi = store.getters["main/currentInstitusi"];
  // const semester = store.getters["main/currentSemester"];
  // const fakultas = store.getters["main/currentFakultas"];

  if (authorized) {
    req.headers.Authorization = `Bearer ${access_token}`;
  } else if (isexpire) {
    if (access_token?.length > 0) {
      req.headers.Authorization = `Bearer ${access_token}`;
    }
  }
  if (tokendikti !== null) {
    req.headers.common["feederToken"] = tokendikti;
  }
  if (tokensister !== null) {
    req.headers.common["sisterToken"] = tokensister;
  }

  // if (semester !== null) {
  //   req.headers.common["currentSemester"] =
  //     semester?.id_smt !== undefined ? semester?.id_smt : "";
  // }
  // if (isAcademicTeam() || userdata.isRektor()) {
  //   // if (institusi !== null) {
  //   //   req.headers.common["currentInstitusi"] = institusi.id_sp;
  //   // }
  //   //
  //   // if (fakultas !== null) {
  //   //   req.headers.common["currentFakultas"] = fakultas.id;
  //   // }
  // } else if (userdata.isDosen() === true) {
  //   // let dosenprodi = userdata.dosenprodi();
  //   req.headers.common["dosen"] = true;
  //   // if (dosenprodi?.id_sms !== undefined) {
  //   //   req.headers.common["currentInstitusi"] = dosenprodi?.id_sp;
  //   //   req.headers.common["currentFakultas"] = dosenprodi?.fakultas?.id;
  //   //   req.headers.common["currentProdi"] = dosenprodi?.id_sms;
  //   // } else {
  //   //   if (institusi !== null)
  //   //     req.headers.common["currentInstitusi"] = institusi?.id_sp;
  //   //   if (fakultas !== null)
  //   //     req.headers.common["currentFakultas"] = fakultas?.id;
  //   // }
  // } else if (userdata.isKaprodi() === true) {
  //   let kaprodi = userdata.kaprodi();
  //   if (kaprodi?.id_sms !== undefined) {
  //     req.headers.common["currentInstitusi"] = kaprodi?.id_sp;
  //     req.headers.common["currentFakultas"] = kaprodi?.fakultas?.id;
  //     req.headers.common["currentProdi"] = kaprodi?.id_sms;
  //   }
  // } else if (userdata.isDekan() === true) {
  //   let dekan = userdata.dekan();
  //   if (dekan?.id !== undefined) {
  //     req.headers.common["currentInstitusi"] = dekan?.id_sp;
  //     req.headers.common["currentFakultas"] = dekan?.fakultas?.id;
  //   }
  // }
  return req;
});

// SIMPONI.interceptors.request.use((req) => {
//   const semester = store.getters["main/currentSemester"];
//
//   if (semester !== null) {
//     req.headers.common["currentSemester"] =
//       semester?.id_smt !== undefined ? semester?.id_smt : "";
//   }
//   req.headers.common["appkey"] = "48fc13f800dbcb02ed168f4b0c141e60";
//   return req;
// });
//
// APPS2.interceptors.request.use((req) => {
//   const semester = store.getters["main/currentSemester"];
//
//   if (semester !== null) {
//     req.headers.common["currentSemester"] =
//       semester?.id_smt !== undefined ? semester?.id_smt : "";
//   }
//   req.headers.common["appkey"] = "x1dRs1aJPsYgsCzQFqKdkU00Tf1LHBhn";
//   return req;
// });
//
// JUPITER.interceptors.request.use((req) => {
//   const semester = store.getters["main/currentSemester"];
//
//   if (semester !== null) {
//     req.headers.common["currentSemester"] =
//       semester?.id_smt !== undefined ? semester?.id_smt : "";
//   }
//   req.headers.common["appkey"] = "0d467ea702527d5ddfb874fd82d8e8df";
//   return req;
// });

axiosRetry(HTTP, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: axiosRetry.isRetryableError,
});

export { HTTP0, HTTP, REFHTTP };
// export { HTTP0, HTTP, REFHTTP, SIMPONI, APPS2, JUPITER };
