import addRecruitment from "@/views/recruitment/addRecruitment";
import detailRecruitment from "@/views/recruitment/detailRecruitment";
import detailDosen from "@/views/dosen/detailDosen";
import detailTendik from "@/views/tendik/detailTendik";
// import detailInpassing from "@/views/inpassing/sdm/detailInpassing";
import detailDiklat from "@/views/sdm/diklat/detailDiklat";
import addTemplateSurat from "@/views/arsip/template-surat/addTemplateSurat";
import detailTemplateSurat from "@/views/arsip/template-surat/detailTemplateSurat";
import makeadata from "@/views/makeadata/indexMmakeadata.vue";

// staff router view
import addInpassingStaff from "@/views/inpassing/addInpassing";
import detailInpassingStaff from "@/views/inpassing/detailInpassing";
import addJabfungStaff from "@/views/jabfung/addJabfung";
import detailJabfungStaff from "@/views/jabfung/detailJabfung";
import addDiklatStaff from "@/views/sdm/diklat/addDiklatStaff";
import detailDiklatStaff from "@/views/sdm/diklat/detailDiklatStaff";
import addRekognisiStaff from "@/views/sdm/rekognisi/addRekognisiStaff";
import detailRekognisiStaff from "@/views/sdm/rekognisi/detailRekognisiStaff";

export const viewList = [
  {
    parent: "recruitment-route",
    path: "recruitment",
    create: addRecruitment,
    edit: detailRecruitment,
  },
  {
    parent: "sdm-route",
    path: "dosen",
    edit: detailDosen,
    view: detailDosen,
  },
  {
    parent: "sdm-route",
    path: "tendik",
    edit: detailTendik,
    view: detailTendik,
  },
  {
    parent: "sdm-route",
    path: "data-inpassing",
    // edit: detailInpassing,
    // view: detailInpassing,
  },
  {
    parent: "sdm-route",
    path: "data-jja",
    // edit: detailInpassing,
    // view: detailInpassing,
  },
  {
    parent: "sdm-route",
    path: "diklat",
    edit: detailDiklat,
    view: detailDiklat,
  },
  {
    parent: "sdm-route",
    path: "template-surat",
    create: addTemplateSurat,
    edit: detailTemplateSurat,
  },
  {
    parent: "sdm-route",
    path: "makeadata",
    create: makeadata,
  },
  {
    parent: "menu-route",
    path: "pangkat-inpassing",
    create: addInpassingStaff,
    edit: detailInpassingStaff,
  },
  {
    parent: "menu-route",
    path: "jabatan-fungsional",
    create: addJabfungStaff,
    edit: detailJabfungStaff,
  },
  {
    parent: "menu-route",
    path: "pendidikan-pelatihan",
    create: addDiklatStaff,
    edit: detailDiklatStaff,
  },
  {
    parent: "menu-route",
    path: "kegiatan-rekognisi",
    create: addRekognisiStaff,
    edit: detailRekognisiStaff,
  },
  {
    parent: "menu-route",
    path: "download-template-surat",
  },
];
