<template>
  <MainLayout>
    <form action="" @submit.prevent="submit">
      <div class="intro-y mt-5 mr-4">
        <div
          v-if="isLoading"
          class="absolute bg-white w-full h-full z-100 flex justify-center items-center z-50"
        >
          <div class="opacity-100">
            <LoadingSpinner :is-loading="isLoading" :use-state="useState" />
          </div>
        </div>
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 2xl:col-span-12 -mb-10 pb-10">
            <div class="intro-y box pb-6">
              <div
                class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">
                  Inpassing
                </h2>

                <button
                  class="disabled-edit opacity-50 inline-flex items-center justify-center py-2 px-3 rounded-md font-medium w-32 ml-auto"
                  disabled
                  @click="editmode = !editmode"
                >
                  <span v-if="editmode">Cancel Edit</span>
                  <span v-else
                    ><edit-icon class="w-4 h-4 mr-1"></edit-icon> Edit
                    Data</span
                  >
                </button>
              </div>

              <div class="grid grid-cols-12 gap-x-8 p-6">
                <dataInpassing
                  :inpassing="detailInpassing"
                  :is-saving="isSaving"
                  @simpanBio="onSimpanBio"
                />
                <div
                  v-if="detailInpassing.sdm_dokumen > 0"
                  class="col-span-12 lg:col-span-12 mt-6 grid grid-cols-12 mb-6 rounded bg-white border-2 border-dashed dark:border-dark-5 rounded-md py-6"
                >
                  <div class="col-span-12 lg:col-span-12">
                    <h2
                      class="font-medium text-base mx-6 mb-3 pb-5 border-b border-gray-200 dark:border-dark-5"
                    >
                      <file-icon class="w-5 h-5 mr-1"></file-icon> Dokumen
                      Lampiran Berkas Jabatan Fungsional
                    </h2>
                  </div>
                  <div
                    v-for="(item, index) in detailInpassing.sdm_dokumen"
                    :key="index"
                    class="col-span-12 lg:col-span-12"
                  >
                    <div class="flex items-center mt-5 px-5">
                      <div class="file">
                        <div href="" class="w-12 file__icon file__icon--file">
                          <div class="file__icon__file-name text-xs">
                            {{ item.jenis_file ? item.jenis_file : "File" }}
                          </div>
                        </div>
                      </div>
                      <div class="ml-4">
                        <div class="font-medium">{{ item.nama }}</div>
                        <div class="text-gray-600 text-xs mt-0.5">
                          {{ item.keterangan }}
                        </div>
                        <div class="text-gray-400 text-xs mt-0.5">
                          Updated:
                          {{
                            $h.tanggal(
                              item.tanggal_upload,
                              "DD MMMM YYYY hh:mm:ss"
                            )
                          }}
                        </div>
                      </div>
                      <div
                        class="flex flex-col items-end w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
                      >
                        <a
                          v-if="item.nama_file"
                          class="btn btn-sm btn-primary w-56"
                          target="_blank"
                          :href="
                            download_url.slice(0, download_url.length - 4) +
                            'storage/sdm/inpassing/' +
                            item.nama_file
                          "
                          download
                        >
                          <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download
                          File
                        </a>
                        <div class="leading-relaxed text-gray-600 text-xs mt-2">
                          {{ item.nama_file ? item.nama_file : "Not found!" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/main/MainLayout";
import dataInpassing from "./partial/dataInpassing";
import LoadingSpinner from "@/components/loading-spinner/Main";
import { sdm } from "@/services/models";

export default {
  components: {
    MainLayout,
    dataInpassing,
    LoadingSpinner,
  },
  data() {
    return {
      editmode: false,
      isLoading: false,
      isSaving: false,
      useState: false,
      id: this.$route.params.id,
      detailInpassing: [],
      download_url: process.env.VUE_APP_API,
    };
  },
  mounted() {
    this.getDetailInpassing();
  },
  methods: {
    async getDetailInpassing() {
      if (this.id?.length > 0) {
        this.isLoading = true;
        sdm.InpassingSdm.getCustomPath(`getDetailInpassing/${this.id}`)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.detailInpassing = res.data.data;
              }
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    onSimpanBio(value) {
      if (value == true) {
        this.editmode = false;
        this.isSaving = true;
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.disabled-edit {
  cursor: not-allowed;
}
</style>
