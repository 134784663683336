<template>
  <div v-if="isLoading" class="p-5">Loading data...</div>
  <form v-else action="" @submit.prevent="submit">
    <div>
      <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
      <div v-if="alertFailed">
        <AlertFailed
          v-for="(item, index) in messageAlert"
          :key="index"
          :message="item"
          :dismissable="true"
        />
      </div>
    </div>
    <div
      class="p-5 bg-gray-50 border-2 border-dashed dark:border-dark-5 rounded-md"
    >
      <div class="grid grid-cols-12 gap-x-8">
        <div class="col-span-12">
          <label class="form-label font-medium w-52">Jenis Kegiatan</label>
          <div class="flex flex-col sm:flex-row pb-3">
            <div
              class="form-check form-control border-0 shadow-none w-auto pl-0 mr-4"
            >
              <input
                id="radio-switch-1"
                v-model="detailDiklat['jenis_kegiatan']"
                class="form-check-input bg-white"
                type="radio"
                value="Pelatihan"
              />
              <label class="form-check-label" for="radio-switch-1"
                >Pelatihan</label
              >
            </div>
            <div
              class="form-check form-control border-0 shadow-none w-auto pl-0 mr-4 mt-2 sm:mt-0"
            >
              <input
                id="radio-switch-2"
                v-model="detailDiklat['jenis_kegiatan']"
                class="form-check-input bg-white"
                type="radio"
                value="Pengembangan"
              />
              <label class="form-check-label" for="radio-switch-2"
                >Pengembangan</label
              >
            </div>
          </div>
          <hr />
        </div>

        <div class="col-span-12 mt-4">
          <label class="form-label font-medium w-52">Lingkup Kegiatan</label>
          <div class="flex flex-col sm:flex-row pb-3">
            <div
              class="form-check form-control border-0 shadow-none w-auto pl-0 mr-4"
            >
              <input
                id="radio-switch-3"
                v-model="detailDiklat['lingkup_kegiatan']"
                class="form-check-input bg-white"
                type="radio"
                value="Internal"
              />
              <label class="form-check-label" for="radio-switch-3"
                >Internal</label
              >
            </div>
            <div
              class="form-check form-control border-0 shadow-none w-auto pl-0 mr-4 mt-2 sm:mt-0"
            >
              <input
                id="radio-switch-4"
                v-model="detailDiklat['lingkup_kegiatan']"
                class="form-check-input bg-white"
                type="radio"
                value="Eksternal"
              />
              <label class="form-check-label" for="radio-switch-4"
                >Eksternal</label
              >
            </div>
          </div>
          <hr />
        </div>
        <div class="col-span-12">
          <div class="flex justify-start flex-col md:flex-row gap-2 mt-6">
            <button class="btn py-3 btn-primary w-full" type="submit">
              <CheckCircleIcon class="w-4 h-4 mr-2" /> Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertFailed from "@/components/alerts/AlertFailed";
import { sdm } from "@/services/models";
import moment from "moment";


export default {
  components: {
    AlertSuccess,
    AlertFailed,
  },
  props: {
    diklat: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      detailDiklat: {
        jenis_kegiatan: this.diklat.jenis_kegiatan,
        lingkup_kegiatan: this.diklat.lingkup_kegiatan,
      },
      isLoading: true,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    diklat: {
      handler: function (data) {
        if (data !== undefined) {
          this.isLoading = false;
          this.$store.dispatch("main/responseMessage", "");
          this.$store.dispatch("main/isSuccess", null);
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    moment: function (date) {
      return moment(date);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    submit() {
      this.isLoading = true;

      let formdata = {
        ...this.detailDiklat,
      };

      if (this.id?.length > 0) {
        sdm.Diklat.postCustomPath("update_lingkup/" + this.id, formdata).then(
          (res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.alertSuccess = true;
                this.messageAlert = res.data.message;

                this.$store.dispatch("main/isSuccess", true);
                this.$store.dispatch("main/responseMessage", res.data.message);
              }
            }

            this.isLoading = false;
            this.scrollToTop();
          }
        );
      }
    },
  },
};
</script>
