<template>
  <LockScreen v-if="isLockScreen && isLock" />
  <router-view v-else />
  <div
    v-if="$route?.name != 'login' && $route?.name != 'forgot-password'"
    id="timer-place"
    class="fixed bottom-5 right-5 bg-blue-50 py-3 px-5 rounded-full z-100 font-semibold"
  >
    00 : 00
  </div>
</template>
<script>
import { defineComponent } from "vue";

import LockScreen from "@/components/lock-screen/Main.vue";

export default defineComponent({
  components: {
    LockScreen,
  },
  data() {
    return {
      ignoreLockScreen: ["login"],
      isLock: false,
      timeId: null,
      timeInterval: null,
    };
  },
  computed: {
    isLockScreen() {
      return this.actionLockScreen();
    },
    isLockScreenStore() {
      return this.$store.state.LockScreenStore.isLocked;
    },
    lastDateTimeIsGTENow() {
      return this.$store.getters["LockScreenStore/isGTENow"];
    },
  },
  mounted() {
    if (this.lastDateTimeIsGTENow) {
      this.$store.dispatch("LockScreenStore/lockScreen");
    } else {
      this.inactive();
    }

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "LockScreenStore/isLocked") {
        this.isLock = state.LockScreenStore.isLocked;

        this.inactive();
        document.getElementsByTagName("body")[0].classList.remove("p-0");
        document.getElementsByTagName("body")[0].classList.add("main");
        this.actionLockScreen();
      }
    });
  },
  methods: {
    countDown() {
      if (this.isLockScreenStore) {
        // Jika halaman sudah terkunci
        this.isLock = true;
      } else {
        // Jika halaman belum terkunci
        this.timeId = setTimeout(() => {
          this.$store.dispatch("LockScreenStore/lockScreen");
          this.isLock = true;
        }, 600000); // 10 menit = 900000
      }
    },
    actionLockScreen() {
      clearTimeout(this.timeId);
      if (!this.ignoreLockScreen.includes(this.$route.name)) {
        this.countDown();
        this.startTimer();
        return true;
      }
      return false;
    },
    inactive() {
      if (!this.isLockScreenStore) {
        document.addEventListener(
          "mousemove",
          () => {
            clearTimeout(this.timeId);
            this.actionLockScreen();
          },
          true
        );
        window.onblur = () => {
          if (!this.ignoreLockScreen.includes(this.$route.name)) {
            this.$store.dispatch("LockScreenStore/setCurrentDateTime");
          }
        };
        window.onfocus = () => {
          this.$store.dispatch("LockScreenStore/setCurrentDateTimeNull");
        };
      }
    },
    startTimer() {
      clearInterval(this.timeInterval);
      let minutes = 10;
      let seconds = 0;
      this.timeInterval = setInterval(() => {
        if (seconds == 0) {
          if (minutes == 0) {
            this.timer = "00:00";
            return;
          } else {
            minutes--;
            seconds = 59;
          }
        } else {
          seconds--;
        }

        if (
          this.$route.name != "login" &&
          this.$route.name != "forgot-password"
        ) {
          if (this.ignoreLockScreen.includes(this.$route.name)) {
            document.getElementById("timer-place").textContent =
              "Waktu tidak terbatas";
          } else {
            document.getElementById("timer-place").textContent = `${minutes}:${
              seconds < 10 ? "0" + seconds : seconds
            }`;
          }
        }
      }, 1000);
    },
  },
});
</script>
<style>
body {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.content {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.tail-select {
  z-index: 100;
}
</style>
