<template>
  <MainLayout :side-title="'.'">
    <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">
          Rincian Data Laporan Hasil Pelatihan & Pengembangan
        </h2>
      </div>
      <div class="post intro-y overflow-hidden box mt-5">
        <div
          class="post__tabs nav nav-tabs flex-col sm:flex-row bg-gray-300 dark:bg-dark-2 text-gray-600"
          role="tablist"
        >
          <TippyTabNav
            :tab-nav-id="'general'"
            :content="'Rincian Pelatihan & Pengembangan'"
            :active-tab="
              activeTab == '' || activeTab === 'general' ? 'active' : ''
            "
            :tab-label="'general'"
            @click="activeTab = 'general'"
          >
            <FileTextIcon class="w-4 h-4 mr-2" /> Detail
          </TippyTabNav>
          <TippyTabNav
            :tab-nav-id="'berkas'"
            :content="'Berkas Laporan'"
            :active-tab="activeTab === 'berkas' ? 'active' : ''"
            :tab-label="'berkas'"
            @click="activeTab = 'berkas'"
          >
            <FileTextIcon class="w-4 h-4 mr-2" /> Berkas Laporan
          </TippyTabNav>
        </div>
        <div class="tab-content">
          <TabContent3
            :tab-id="'general'"
            :active-tab="
              activeTab == '' || activeTab === 'general' ? 'active' : ''
            "
          >
            <editformdataDiklat
              v-if="activeTab == '' || activeTab === 'general'"
              @detailDataDiklat="setDetailDataDiklat"
            ></editformdataDiklat>
          </TabContent3>
          <TabContent3
            :tab-id="'berkas'"
            :active-tab="activeTab === 'berkas' ? 'active' : ''"
          >
            <editformdataBerkasDiklat
              v-if="activeTab === 'berkas'"
            ></editformdataBerkasDiklat>
          </TabContent3>
        </div>
      </div>
    </div>
    <template #rightside>
      <div class="post intro-y overflow-hidden box mt-5">
        <div class="box p-5 rounded-md">
          <div class="px-3 pb-3 relative">
            <div class="event p-3 -mx-3 flex items-center">
              <div class="pr-10">
                <div class="text-gray-600 text-xs mt-0.5">
                  Last updated: {{ $h.tanggal(detailDiklat["last_updated"]) }}
                </div>
              </div>
            </div>
            <span
              class="flex items-center absolute top-0 bottom-0 my-auto right-0"
            >
              <EditIcon class="w-4 h-4 text-gray-600"></EditIcon>
            </span>
          </div>
          <editformdataLingkupDiklat
            v-if="detailDiklat['id_diklat']"
            :diklat="detailDiklat"
          ></editformdataLingkupDiklat>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/main/MainLayout";
import editformdataDiklat from "@/views/sdm/diklat/partial/editformdataDiklat";
import editformdataBerkasDiklat from "@/views/sdm/diklat/partial/editformdataBerkasDiklat";
import editformdataLingkupDiklat from "@/views/sdm/diklat/partial/editformdataLingkupDiklat";
import TippyTabNav from "@/components/tabs/TippyTabNav";
import TabContent3 from "@/components/tabs/TabContent3";

export default {
  components: {
    MainLayout,
    editformdataDiklat,
    editformdataBerkasDiklat,
    editformdataLingkupDiklat,
    TippyTabNav,
    TabContent3,
  },
  data() {
    return {
      detailDiklat: [],
      activeTab: null,
      tabOnStudy: "general",
    };
  },
  mounted() {
    this.activeTab = this.$route.params?.tab;
    if (this.activeTab === undefined) this.activeTab = "";
  },
  methods: {
    setDetailDataDiklat(val) {
      this.detailDiklat = val;
      if (this.detailDiklat["id_sdm"] == null) return 0;
    },
  },
};
</script>
