import { akademik, dosen, master, pddikti } from "@/services/models";
import store from "@/store";

const options = {
  fetchDosen(filterData, url = null) {
    let listDosen = [];
    let defaultOpt = {
      id: 0,
      value: "",
      text: "Pilih Dosen",
      selected: true,
    };
    listDosen.push(defaultOpt);
    dosen.Dosen.fetchUrlPrefix("is_active", url).then((result) => {
      if (result?.status === 200) {
        result.data.forEach((item) => {
          let newoption = {
            id: item.id,
            value: item.KODE,
            text: `${item.KODE} | ${item.NM_DOSEN}`,
            reg_ptk: item?.regptk,
            selected: filterData?.dosen?.current?.id === item.id,
          };
          listDosen.push(newoption);
        });
      }
      store.dispatch("main/listOptions", { dosen: listDosen });
    });
  },
  fetchKurikulum(filterData) {
    let listOptions = [];
    let defaultOpt = {
      id: 0,
      value: "",
      text: "Pilih Kurikulum",
      selected: true,
    };
    listOptions.push(defaultOpt);
    akademik.Kurikulum.getAll().then((result) => {
      if (result.status === 200) {
        result.data.data.forEach((item) => {
          let newoption = {
            id: item.id,
            value: item.id_kurikulum_sp,
            text: item.nm_kurikulum_sp,
            id_sms: item.id_sms,
            selected: filterData?.kurikulum?.current?.id === item.id,
          };
          listOptions.push(newoption);
        });
      }
      store.dispatch("main/listOptions", { kurikulum: listOptions });
      ///return listOptions;
    });
    //return listOptions;
  },
  fetchProdi(filterData, currentInstitusi = false) {
    let listOptions = [];
    let defaultOpt = {
      id: 0,
      value: "",
      text: "Pilih Prodi",
      selected: false,
    };
    listOptions.push(defaultOpt);
    master.Prodi.getAll().then((result) => {
      if (result.status === 200) {
        result.data.data.forEach((item) => {
          let newoption = {
            id: item.id,
            value: item.id_sms,
            text:
              item.jenjang + " " + item.nama + " - " + item?.induk?.nama_alias,
            id_sms: item.id_sms,
            kode_angka: item.kode_angka,
            kode_huruf: item.kode_huruf,
            selected: filterData?.prodi?.current?.id === item.id,
          };
          if (currentInstitusi === true) {
            let cins = store.getters["main/currentInstitusi"];
            if (cins?.id_sp === item?.id_sp) {
              listOptions.push(newoption);
            }
          } else {
            listOptions.push(newoption);
          }
        });
      }
      store.dispatch("main/listOptions", { prodi: listOptions });
    });
  },
  fetchFakultas(current) {
    let listOptions = [];
    let defaultOpt = {
      id: "0",
      value: "0",
      text: "Pilih Fakultas",
      selected: false,
    };
    listOptions.push(defaultOpt);
    pddikti.MasterFakultas.getAllActive().then((result) => {
      if (result?.status === 200) {
        result?.data?.data.forEach((item) => {
          let newoption = {
            id: item.id,
            value: item.id,
            text: item.nama,
            kode_pddikti: item.kode_pddikti,
            nama: item.nama,
            nama_alias: item.nama_alias,
            id_sp: item.id_sp,
            selected: current?.id === item.id,
          };
          listOptions.push(newoption);
        });
      }

      store.dispatch("main/listOptions", { faculties: listOptions });
    });
  },

  fetchSemester(filterData) {
    let listOptions = [];
    let defaultOpt = {
      id: 0,
      value: "",
      text: "Pilih Semester",
      selected: true,
    };
    listOptions.push(defaultOpt);
    master.Semester.getAllActive().then((result) => {
      if (result.status === 200) {
        result.data.data.forEach((item) => {
          let newoption = {
            id: item.id_smt,
            value: item.id_smt,
            text: item.nm_smt,
            id_smt: item.id_smt,
            nm_smt: item.nm_smt,
            smt: item.smt,
            id_thn_ajaran: item.id_thn_ajaran,
            tbn_ajaran: item.thn_ajaran,
            selected:
              String(filterData?.semester?.current?.id) === String(item.id_smt),
          };
          listOptions.push(newoption);
        });
      }
      store.dispatch("main/listOptions", { semester: listOptions });
    });
  },
};

/*const methods = {
  setListOptionsProdi(filterData) {}
};*/

const install = (app) => {
  app.config.globalProperties.$list = options;
};

export { install as default, options as option };
