import API from "../api";
export const sdm = {
  KeaktifanPtk: new API("keaktifan_ptk"),
  SDM: new API("sdm"),
  Dosen: new API("dosen"),
  Tendik: new API("tendik"),
  SMS: new API("sms"),
  PTK: new API("reg_ptk"),
  Pengumuman: new API("pengumuman"),
  InpassingSdm: new API("inpassingsdm"),
  JabfungSdm: new API("jabfungsdm"),
  Diklat: new API("diklat"),
  Rekognisi: new API("rekognisi"),
  Rekrutmen: new API("rekrutmen"),
  RekrutmenRef: new API("rekrutmenref"),
  Simarta: new API("simarta"),
  MasterProdi: new API("masterprodi"),
  TemplateSurat: new API("template-surat"),
};
