// import { menuSdm } from "@/utils/menus";

const mainmenus = [
  {
    icon: "HomeIcon",
    pageName: "dashboard",
    title: "Dashboard",
    ability: ["*"],
    subMenu: [
      {
        icon: "HomeIcon",
        pageName: "dashboard",
        ability: ["*"],
        title: "Beranda",
      },
      {
        icon: "HomeIcon",
        pageName: "grafik-stastik",
        ability: ["*"],
        title: "Grafik",
      },
      {
        icon: "HomeIcon",
        pageName: "template-surat",
        ability: ["*"],
        title: "Template Surat",
      },
    ],
  },
  {
    icon: "UserPlusIcon",
    pageName: "recruitment",
    title: "Recruitment",
    ability: ["*"],
  },
  {
    icon: "usersIcon",
    pageName: "sdm",
    title: "SDM",
    ability: ["*"],
    subMenu: [
      {
        icon: "UserIcon",
        pageName: "dosen",
        ability: ["*"],
        title: "Dosen",
      },
      {
        icon: "UserIcon",
        pageName: "tendik",
        ability: ["*"],
        title: "Tenaga Pendidik",
      },
      {
        icon: "UserIcon",
        pageName: "data-inpassing",
        ability: ["*"],
        title: "Inpassing",
      },
      {
        icon: "UserIcon",
        pageName: "data-jja",
        ability: ["*"],
        title: "JJA (Jabfung)",
      },
    ],
  },
  {
    icon: "briefcaseIcon",
    pageName: "",
    ability: ["*"],
    title: "Pelatihan & Pengembangan",
    subMenu: [
      {
        icon: "briefcaseIcon",
        pageName: "diklat",
        ability: ["*"],
        title: "Pelatihan",
      },
      {
        icon: "briefcaseIcon",
        pageName: "rekognisi",
        ability: ["*"],
        title: "Kegiatan Rekognisi",
      },
    ],
  },
  {
    icon: "fileIcon",
    pageName: "makeadata",
    ability: ["*"],
    title: "Make a Data",
  },
  {
    icon: "archiveIcon",
    pageName: "",
    ability: ["*"],
    title: "Arsip",
  },
  // {
  //   icon: "castIcon",
  //   pageName: "faq",
  //   ability: ["*"],
  //   title: "Masukan dan usulan tentang SDM",
  // },
];

const install = (app) => {
  app.config.globalProperties.$ac_menu = mainmenus;
};

export { install as default, mainmenus };
